import React from 'react'
import { Link } from 'gatsby'
import style from '../../layouts/style.module.sass'

const Menu = ({ siteTitle }) => (
  <div className={style.menuListSection}>
    <ul className={style.menuList}>
      <li className={style.menuListItem}><Link className={style.menuListItemLink}
          to="/">Top</Link></li>
      <li className={style.menuListItem}><Link className={style.menuListItemLink}
          to="/profile/">Profile</Link></li>
      <li className={style.menuListItem}><Link className={style.menuListItemLink}
          to="/contact/">Contact</Link></li>
    </ul>
  </div>
)

export default Menu
