import React from 'react'
import { Link } from 'gatsby'
import Menu from '../Menu';
import style from '../../layouts/style.module.sass'

const Header = ({ siteTitle }) => (
  <header className={style.header}>
    <div
      className={style.headerWrapper}
    >
      <h1 className={style.headerHeadline}>
        <Link
          to="/"
          className={style.headerTitle}
        >
          {siteTitle}
        </Link>
      </h1>
      <Menu className={style.menu} />
    </div>
  </header>
)

export default Header
