import React from 'react'
import style from '../../layouts/style.module.sass'

export default class LinkTop extends React.Component {
  constructor() {
    super()

    this.state = {
      intervalId: 0,
    }
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId)
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx)
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    )
    this.setState({ intervalId: intervalId })
  }

  render() {
    return (
      <p className={style.linkTopWrapper}>
        <span
          className={style.linkTop}
          onClick={() => {
            this.scrollToTop()
          }}
        >
          Page Top
        </span>
      </p>
    )
  }
}
