import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../layouts'
import style from '../layouts/style.module.sass'
import LinkTop from '../components/LinkTop'
import Helmet from 'react-helmet'

const IndexPage = props => (
  <Layout>
    <Helmet
      title="webdevelopment.jp"
      meta={[
        {
          name: 'description',
          content:
            'ホームページ・ウェブサイト制作、システム構築、コーディング、デザインにまつわるご相談を承ります。ウェブサイトで何をしたらいいのか分からない方、すでにあるウェブサイトの改善などについてもご相談ください。',
          name: 'google-site-verification',
          content: 'vAR37vD6RfeyUJV81X-SW4MOhxigC_7TNhM2TrTo6HQ',
        },
      ]}
    />
    <Img fluid={props.data.imageBanner.childImageSharp.fluid} />
    <h1 className={style.frontHeadline}>Webに関する問題を解決します</h1>
    <p>例えばこんなことを解決できます</p>
    <ul>
      <li>WordPressを使ったサイト制作を作りたい</li>
      <li>1枚ペラのランディングページを作りたい</li>
      <li>既存ウェブサイトのリニューアルをしたい</li>
      <li>正当なSEO対策をしたい</li>
      <li>
        そもそもウェブで何をしたらいいかわからないのでコンサルティングを受けたい
      </li>
    </ul>
    <h1 className={style.frontHeadline}>ご依頼いただくメリット</h1>
    <ul>
      <li>デザイナーとの協業でワンストップでのサイト制作が可能</li>
      <li>予算内に収める方法のご提案が可能</li>
      <li>継続的運用のお手伝いが可能</li>
      <li>海外オフィスへの出張が可能</li>
    </ul>
    <h1 className={style.frontHeadline}>実績</h1>
    <ul>
      <li>関わったECサイトで3年でのべ10億円以上の売上を達成</li>
      <li>士業関連キーワードで検索順位1位獲得</li>
      <li>Yahooトピックス掲載</li>
      <li>ダウンロード販売サイトの構築</li>
      <li>マッチングサイトの構築</li>
      <li>顧客管理システムの構築</li>
      <li>Googleスプレッドシートと連携したシステム構築</li>
    </ul>
    <LinkTop scrollStepInPx="50" delayInMs="16.66" />
  </Layout>
)

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    imageBanner: file(relativePath: { eq: "images/banner.jpg" }) {
      ...fluidImage
    }
  }
`
