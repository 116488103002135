import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/Header'
import Footer from '../components/Footer'
//import './index.css'
import style from './style.module.sass'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          meta={[
            {
              title: data.site.siteMetadata.title,
              name: 'description',
              content:
                'ウェブ制作、システム構築、コーディング、デザインにまつわるご相談を承ります。',
            },
            {
              name: 'keywords',
              content:
                'ウェブ制作, web development, ウェブ開発, ウェブサイト制作',
            },
          ]}
        >
          <html lang="jp" />
          <link rel="apple-touch-icon" href="/icons/icon-512x512.png" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className={style.main}>{children}</main>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
