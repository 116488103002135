import React from 'react'
import Link from 'gatsby-link'
import style from '../../layouts/style.module.sass'

const Footer = ({ siteTitle }) => (
  <footer className={style.footer}>
    <div
      style={{
        margin: '0 auto',
        textAlign: 'center',
        maxWidth: 960,
        padding: '1.45rem 1.0875rem',
      }}
    >
      <p>
        <Link to="/" className={style.copyright}>
          &copy; 2019 {siteTitle}
        </Link>
      </p>
    </div>
  </footer>
)

export default Footer
